export const environment = {
  production: true,
  api: "https://appcc.habil.com.br/",
  reports: "http://central.habil.com.br/reports",
  apiLogs: "https://logs.habil10.com.br",
  devManager: "http://central.habil.com.br:4520/manager",
  socket: "https://logsocket.habil10.com.br",
  apiNuvem: "https://app.habil.net.br",
  apiDatabase: "https://appcc.habil.com.br/dbman"
};
