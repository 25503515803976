<p-scrollPanel [style]="{ width: '100%', height: '300px'}">
    <div class="p-fluid p-grid">
        <div class="p-col-6">
            <habil-label name="txtLogin"
                label="Login (e-mail)">
                <input type="text"
                    pInputText
                    [(ngModel)]="obj.login"
                    id="txtLogin">
            </habil-label>
        </div>
        <div class="p-col-6">
            <habil-label name="txtSenha"
                label="Senha">
                <input type="text"
                    pInputText
                    type="password"
                    [(ngModel)]="obj.senha"
                    id="txtSenha">
            </habil-label>
        </div>
        <div class="p-col-12">
            <habil-label name="txtNome"
                label="Nome">
                <input type="text"
                    pInputText
                    [(ngModel)]="obj.nome"
                    id="txtNome">
            </habil-label>
        </div>
        <div class="p-col-6">
            <habil-label name="txtLembrete"
                label="Lembrete de Senha">
                <input type="text"
                    pInputText
                    [(ngModel)]="obj.lembreteSenha"
                    id="txtLembrete">
            </habil-label>
        </div>
        <div class="p-col-6">
            <habil-label name="txtTelefone"
                label="Telefone">
                <input type="text"
                    pInputText
                    [(ngModel)]="obj.telefone"
                    id="txtTelefone">
            </habil-label>
        </div>
    </div>
</p-scrollPanel>

<hr>
<div class="p-fluid p-grid">
    <div class="p-col-3">
        <button pButton
            class="p-button-danger p-button-outlined"
            label="Cancelar"
            icon="pi pi-times"
            (click)="cancelar()"></button>
    </div>
    <div class="p-col"></div>
    <div class="p-col-3">
        <button pButton
            class="p-button-success"
            label="Salvar"
            icon="pi pi-check"
            (click)="salvar()"></button>
    </div>
</div>