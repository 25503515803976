import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { eventInNotify } from 'src/app/app.component';
import { LogsService } from 'src/app/services/logs.service';

@Component({
  selector: 'habil-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  constructor(private service: LogsService, private breadcrumbService: BreadcrumbService) {
    this.breadcrumbService.setItems([{ label: 'Logs' }]);
  }
  param: {
    cnpj: string,
    ip: string,
    controller: string
  } = {
      cnpj: '',
      ip: '',
      controller: ''
    }

  logsH10: any = {
    start: 0,
    length: 50,
    param: {},
    result: [],
    selection: null
  }

  logsImport: any = {
    start: 0,
    length: 50,
    param: {},
    result: [],
    selection: null
  }

  loading = false;

  ngOnInit(): void {
    this.buscarH10();
    eventInNotify.onclick = (evt: any) => {
      this.buscarH10();
    }
  }

  buscarH10() {
    const l = this.logsH10;
    l.param = {};
    const p = this.param;
    if (p.cnpj) {
      l.param.cnpj = p.cnpj;
    }
    if (p.ip) {
      l.param.ip = p.ip;
    }
    if (p.controller) {
      l.param.controller = p.controller;
    }
    this.loading = true;
    this.service.getErrors(l.start, l.length, [l.param]).subscribe({
      next: (val) => {
        this.loading = false;
        if (val) {
          this.logsH10.result = [...val];
          this.logsH10.selection = this.logsH10.result[0];
        }
      },
      error: (err) => {
        this.loading = false;
      }
    })
  }

  buscarImportacao() {
    this.loading = true;
    this.service.getImportacoes(0, 100, []).subscribe({
      next: (val) => {
        this.loading = false;
        if (val) {
          this.logsImport.result = [...val];
          this.logsImport.selection = this.logsImport.result[0];
        }
      },
      error: (err) => {
        this.loading = false;
      }
    })
  }

}
