import { AbstractModel } from "./abstract-model";
import { Rotina } from "./eacesso-rotina";
import { Classificacao } from "./registro-produto";

export class HabilSpaProduto extends AbstractModel {
    nomeProduto: string = "";
    codigo: string = "";
    acessos: string[] = [];
    acessosComDescricao?: Rotina[];
    classificacao: Classificacao;
}

export class HabilSpaRegistroLicenca {
    expDate: Date = new Date();
    nroLicencas: number = 1;
    acessoGeral: boolean = false;
    acessos: string[] = [];
    empresas: HabilSpaRegistroLicencaEmpresa[] = [];
    ip?: string;
}

export class HabilSpaRegistroLicencaEmpresa {
    documento: string = "";
    nome: string = "";
    vcto: Date = new Date();
    licencas: number = 0;
}