import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AbstractService } from "./abstract.service";

@Injectable({
    providedIn: "root",
})
export class DBManagerService {
    constructor(private http: HttpClient) { }

    restoreDatabase(backupFile: File, database: string, host: string, drop: boolean): Observable<any> {
        const headers = new HttpHeaders({
            'Authorization': `${AbstractService.token()}`
        });
        host = host?.replace(':5432', '')
        const params = new HttpParams()
            .set('host', host)
            .set('database', database)
            .set('drop', drop ? 'true' : 'false');

        const formData = new FormData();
        formData.append('backup', backupFile);

        return this.http.post(environment.apiDatabase + '/restore', formData, { headers, params });
    }

}
