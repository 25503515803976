import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AbstractModel } from "../models/abstract-model";
import { clone } from "../utilities/util/clone-utils";
import { DateUtils } from "../utilities/util/date-utils";
import { FormatUtils } from "../utilities/util/format-utils";
import { HttpRequest, UriBuilder } from "../utilities/util/http-request";
import { LoginService } from "./login.service";
import { SearchParams } from "../utilities/util/search/search-params";

@Injectable({
    providedIn: "root",
})
export abstract class AbstractService<T extends AbstractModel> {
    public static readonly: boolean = true;
    static _token = "";
    public static token(): string {
        if (this._token) {
            return this._token;
        }
        const t = localStorage.getItem("token");
        if (t) {
            this._token = t.replace(/"/, "").replace(/"/, "");
            return this._token;
        }
        return "";
    }
    public static app = "Central do Cliente";
    public static municipios = [];

    http: HttpClient;
    loginService: LoginService;

    constructor(http: HttpClient, loginService: LoginService) {
        this.http = http;
        this.loginService = loginService;
    }

    doInitNullObjs(t: T) {}

    abstract titulo: string;

    abstract endpoint(): string;

    abstract rotina(): string;

    abstract colunas(): any[];

    abstract novo(selection?: any);

    abstract abrir(obj: T);

    abstract edit(obj: T);

    abstract routeLista();

    delete(id: number): Observable<any> {
        const url = this.baseUrl() + "/delete/" + id;

        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.delete<any>(url, { headers: h });
    }

    count(search: string, searchParam?: any[]): Observable<number> {
        return new HttpRequest<number>(this.http)
            .endpoint(this.endpoint())
            .uri("record-count")
            .header(
                "search-params",
                searchParam != undefined ? FormatUtils.toJson(searchParam) : ""
            )
            .header("search", search)
            .doGet();
    }

    getSearchFields(): Observable<any> {
        const url = this.baseUrl() + "/auto-complete/fields";
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.get<any>(url, { headers: h });
    }

    findById(id: number): Observable<T> {
        const url = this.baseUrl() + "/" + id;

        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.get<T>(url, { headers: h });
    }

    findByIdLazyLoaded(id: number): Observable<any> {
        const url = this.baseUrl() + "/lazy-loaded/" + id;
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.get<any>(url, { headers: h });
    }

    findByIdLazyLoadedPorEmpresa(id: number): Observable<T> {
        const url = this.baseUrl() + "/lazy-loaded/" + id;
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.get<T>(url, { headers: h });
    }

    corrigeAtributos(t): T {
        try {
            if (t.concluidaEm) {
                t.concluidaEm = DateUtils.dateToString(t.concluidaEm);
            }
            if (t.proximoReajuste) {
                t.proximoReajuste = DateUtils.dateToString(t.proximoReajuste);
            }
            if (t.ultimoReajuste) {
                t.ultimoReajuste = DateUtils.dateToString(t.ultimoReajuste);
            }
            if (t.dataInicio) {
                t.dataInicio = DateUtils.dateToString(t.dataInicio);
            }
            if (t.relatorioPadrao && t.relatorioPadrao.id) {
                t.relatorioPadrao = { id: t.relatorioPadrao.id };
            }
            if (t.dataHoraEntrada) {
                t.dataHoraEntrada = DateUtils.dateTimeToString(
                    t.dataHoraEntrada
                );
            }
            if (t.proximoContatoData) {
                t.proximoContatoData = DateUtils.dateTimeToString(
                    t.proximoContatoData
                );
            }
            if (t.cadastro) {
                t.cadastro = DateUtils.dateTimeToString(t.cadastro);
            }
            if (t.previsaoValidade) {
                t.previsaoValidade = DateUtils.dateTimeToString(
                    t.previsaoValidade
                );
            }
            if (t.data) {
                t.data = DateUtils.dateTimeToString(t.data);
            }
            if (t.dataLimite) {
                t.dataLimite = DateUtils.dateTimeToString(t.dataLimite);
            }
            if (t.dataInicial) {
                t.dataInicial = DateUtils.dateTimeToString(t.dataInicial);
            }
            if (t.dataFinal) {
                t.dataFinal = DateUtils.dateTimeToString(t.dataFinal);
            }
            if (t.dataNotificacao) {
                t.dataNotificacao = DateUtils.dateTimeToString(
                    t.dataNotificacao
                );
            }
            if (t.emissao) {
                t.emissao = DateUtils.dateTimeToString(t.emissao);
            }
            if (t.inicioViagem) {
                t.inicioViagem = DateUtils.dateTimeToString(t.inicioViagem);
            }
        } catch {}
        return t;
    }

    save(o: any): Observable<any> {
        try {
            const url = this.baseUrl() + "/save";
            let t = clone(o);
            t = this.corrigeAtributos(t);
            const params = new URLSearchParams();
            let obj: any = FormatUtils.toJson(t).toString();
            try {
                obj = obj.replaceAll('\\"\\"', "null");
            } catch (e) {
                console.log(e);
            }
            // obj = obj.replaceAll( '""', "null"); #5120
            params.append("obj", obj);

            const h = new HttpHeaders({
                Accept: "application/json; charset=utf-8",
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Auth-Token": AbstractService.token(),
            });
            return this.http.post<any>(url, params.toString(), { headers: h });
        } catch (e) {
            return new Observable((observable) => {
                console.log(o);
                console.log(e);
                observable.error(e.message);
            });
        }
    }

    saveR(o: any): Observable<HttpResponse<any>> {
        let t = clone(o);
        if (t.cadastro) {
            try {
                t.cadastro = DateUtils.dateTimeToString(t.cadastro);
            } catch {}
        }
        t = this.corrigeAtributos(t);

        const url = this.baseUrl() + "/save";
        const params = new URLSearchParams();
        let obj: any = FormatUtils.toJson(t).toString();
        try {
            obj = obj.replaceAll('\\"\\"', "null");
        } catch (e) {
            console.log(e);
        }

        params.append("obj", obj);

        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.post<any>(url, params.toString(), {
            headers: h,
            observe: "response",
        });
    }

    saveAll(list: any[]): Observable<any[]> {
        const url = this.baseUrl() + "/bulk-save";
        const params = new URLSearchParams();
        params.append("lista", FormatUtils.toJson(list));
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.post<any[]>(url, params.toString(), { headers: h });
    }

    saveAllNR(list: T[]): Observable<void> {
        const url = this.baseUrl() + "/bulk-save";
        const params = new URLSearchParams();
        params.append("lista", FormatUtils.toJson(list));
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.post<void>(url, params.toString(), { headers: h });
    }

    lazyLoadField(id: number, fieldName: string): Observable<any> {
        const url = this.baseUrl() + "/lazy-load/" + id;
        const h = new HttpHeaders({
            Accept: "application/json; charset=utf-8",
            "X-Auth-Token": AbstractService.token(),
            fieldName: fieldName,
        });
        return this.http.get<Blob>(url, { headers: h });
    }

    geraReferencia(): Observable<number> {
        const url = this.baseUrl() + "/gera-referencia";
        const h = new HttpHeaders({
            "Content-Type": "multipart/form-data;charset=UTF-8",
            "X-Auth-Token": AbstractService.token(),
        });
        return this.http.get<number>(url, { headers: h });
    }

    findAllTO(
        start: number,
        length: number,
        search: any = '',
        order: any,
        campos: string[],
        searchParam?: SearchParams[],
        ignorarEmpresaAtiva: boolean = false,
      ): Observable<any[]> {
        if (!campos.includes('id')) {
          campos = ['id', ...campos];
        }
        if (searchParam) {
          searchParam.push(new SearchParams('deleted', ['false']));
        }
        if (searchParam?.length) {
          for (const sp of searchParam) {
            if (sp.paramName == 'search') {
              if (sp.paramValue?.length) {
                sp.paramValue[0] = (sp.paramValue[0] || '')
                  .replace(/[àáâãäå]/, 'a')
                  .replace(/[èéêë]/, 'e')
                  .replace(/[ìíîï]/, 'i')
                  .replace(/[òóôõö]/, 'o')
                  .replace(/[ùúûü]/, 'u')
                  .replace(/[ç]/, 'c')
                  //.replace(/[^a-z0-9]/gi, '')
                  .trim();
              }
            }
          }
        }
        if (!order) order = '';
        return new HttpRequest<any[]>(this.http)
          .endpoint(this.endpoint())
          .uri(
            new UriBuilder()
              .subpath('list/all')
              .subpath(start.toString())
              .subpath(length.toString())
              .build(),
          )
          //.header('order-params', FormatUtils.toJson(order))
          .header('empresa-ativa', '1')
          .header('search', search/*.replaceAll('%', '%25')*/)
          .header('ignorar-empresa-ativa', ignorarEmpresaAtiva.toString())
          .header(
            'search-params',
            searchParam != undefined ? FormatUtils.toJson(searchParam) : '',
          )
          .header('campos', campos.toString().trim().replace('-', '.'))
          .doGet();
      }

    findAll(
        start: number,
        length: number,
        search: string,
        order: any = "",
        searchParam?: any[]
    ): Observable<T[]> {
        if (order != undefined && order.toString().length <= 1) {
            order = "";
        } else {
            order = FormatUtils.toJson(order);
        }
        return new HttpRequest<any[]>(this.http)
            .endpoint(this.endpoint())
            .uri(
                new UriBuilder()
                    .subpath("all")
                    .subpath(start.toString())
                    .subpath(length.toString())
                    .build()
            )
            .header("search", search)
            .header("order-params", order)
            .header(
                "search-params",
                searchParam != undefined ? FormatUtils.toJson(searchParam) : ""
            )
            .doGet();
    }

    handleError(e?) {
        this.loginService.trataExcecoes(e);
    }

    baseUrl(): string {
        return environment.api + "/" + this.endpoint();
    }

    public static removeFromLocalStorage(pName: string) {
        localStorage.removeItem(pName);
    }

    public static saveToLocalStorage(pName: string, value: any) {
        localStorage.setItem(pName, FormatUtils.toJson(value));
    }

    public static getLocalStorage(pName: any) {
        let data: any = localStorage.getItem(pName);
        if (data != undefined && data != null) {
            data = JSON.parse(data);
            return data;
        } else {
            return;
        }
    }
}
