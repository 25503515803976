import { NewsComponent } from './pages/news/news.component';
import {
  ConsultaContagemEmissaoComponent
} from './pages/cliente/consulta-contagem-emissao/consulta-contagem-emissao.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { AdminUsuarioComponent } from './pages/admin-usuario/admin-usuario.component';
import {
  CadastroAdminUsuarioComponent
} from './pages/admin-usuario/cadastro-admin-usuario/cadastro-admin-usuario.component';
import { ParceiroComponent } from './pages/parceiro/parceiro.component';
import { CadastroParceiroComponent } from './pages/parceiro/cadastro-parceiro/cadastro-parceiro.component';
import { ProdutoComponent } from './pages/produto/produto.component';
import { CadastroProdutosComponent } from './pages/produto/cadastro-produtos/cadastro-produtos.component';
import { ClassificacaoComponent } from './pages/classificacao/classificacao.component';
import {
  CadastroClassificacaoComponent
} from './pages/classificacao/cadastro-classificacao/cadastro-classificacao.component';
import { CategoriaRelatorioComponent } from './pages/categoria-relatorio/categoria-relatorio.component';
import {
  CadastroCategoriaRelatorioComponent
} from './pages/categoria-relatorio/cadastro-categoria-relatorio/cadastro-categoria-relatorio.component';
import { LogOperacoesComponent } from './pages/log-operacoes/log-operacoes.component';
import { EstimativaAcessoComponent } from './pages/estimativa-acesso/estimativa-acesso.component';
import { ClienteComponent } from './pages/cliente/cliente.component';
import { CadastroClienteComponent } from './pages/cliente/cadastro-cliente/cadastro-cliente.component';
import { ConsultaClienteComponent } from './pages/cliente/consulta-cliente/consulta-cliente.component';
import { CadastroNewsComponent } from './pages/news/cadastro-news/cadastro-news.component';
import { CfgComponent } from './pages/cfg/cfg.component';
import { LiberacaoComponent } from './pages/liberacao/liberacao.component';
import { PlanoSuporteComponent } from './pages/plano-suporte/plano-suporte.component';
import {
  CadastroPlanoSuporteComponent
} from './pages/plano-suporte/cadastro-plano-suporte/cadastro-plano-suporte.component';
import { AtendimentoComponent } from './pages/atendimento/atendimento.component';
import { AuthService } from './services/auth.service';
import { ServidorComponent } from './pages/balanceamento/servidor/servidor.component';
import { CadastroServidorComponent } from './pages/balanceamento/cadastro-servidor/cadastro-servidor.component';
import {
  ConsultaLiberacoesHerpComponent
} from './pages/cliente/consulta-liberacoes-herp/consulta-liberacoes-herp.component';
import { GerenciamentoServidorComponent } from './pages/nuvem/gerenciamento-servidor/gerenciamento-servidor.component';
import { ConsultaLiberacoesHeComponent } from './pages/cliente/consulta-liberacoes-he/consulta-liberacoes-he.component';
import { CadastroProdutoHabilCobrancasComponent } from './pages/produto/cadastro-produto-habil-cobrancas/cadastro-produto-habil-cobrancas.component';
import { CadastroProdutoLimberChefComponent } from './pages/produto/cadastro-produto-limber-chef/cadastro-produto-limber-chef.component';
import { CadastroProdutoHabilFranqueadoraComponent } from './pages/produto/cadastro-produto-habil-franqueadora/cadastro-produto-habil-franqueadora.component';
import { CadastroProdutoHabilMobilePdvComponent } from './pages/produto/cadastro-produto-habil-mobile-pdv/cadastro-produto-habil-mobile-pdv.component';
import { LogsComponent } from './pages/logs/logs.component';
import { DatabaseManagementComponent } from './pages/database-management/database-management.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
        [
          {path: '', component: AppLoginComponent},
          {
            path: '',
            component: AppMainComponent,
            children: [
              {
                path: 'home',
                component: DashboardDemoComponent,
                canActivate: [AuthService],
              },
              {
                path: 'adm/usuarios',
                component: AdminUsuarioComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/usuarios/cadastro',
                component: CadastroAdminUsuarioComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/parceiros',
                component: ParceiroComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/parceiros/cadastro',
                component: CadastroParceiroComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/produtos',
                component: ProdutoComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/produtos/cadastro',
                component: CadastroProdutosComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              }, 
              {
                path: 'adm/produtos/cadastro-habil-cobrancas',
                component: CadastroProdutoHabilCobrancasComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/produtos/cadastro-habil-franqueadora',
                component: CadastroProdutoHabilFranqueadoraComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/produtos/cadastro-limber-chef',
                component: CadastroProdutoLimberChefComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/produtos/cadastro-habil-mobile-pdv',
                component: CadastroProdutoHabilMobilePdvComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/categorias-relatorios',
                component: CategoriaRelatorioComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/categorias-relatorios/cadastro',
                component: CadastroCategoriaRelatorioComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/plano-suporte',
                component: PlanoSuporteComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/plano-suporte/cadastro',
                component: CadastroPlanoSuporteComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/servidor',
                component: ServidorComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/servidor/cadastro',
                component: CadastroServidorComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/log',
                component: LogOperacoesComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/estimativas',
                component: EstimativaAcessoComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'adm/nuvem',
                component: GerenciamentoServidorComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'clientes/registro',
                component: ClienteComponent,
                canActivate: [AuthService],
                data: {grupo: 3},
              },
              {
                path: 'clientes/registro/cadastro',
                component: CadastroClienteComponent,
                canActivate: [AuthService],
                data: {grupo: 3},
              },
              {
                path: 'clientes/classificacao',
                component: ClassificacaoComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'clientes/classificacao/cadastro',
                component: CadastroClassificacaoComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'clientes/consulta',
                component: ConsultaClienteComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'clientes/contagem',
                component: ConsultaContagemEmissaoComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'clientes/liberacoes-herp',
                component: ConsultaLiberacoesHerpComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'clientes/liberacoes-he',
                component: ConsultaLiberacoesHeComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'news',
                component: NewsComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'news/cadastro',
                component: CadastroNewsComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'cfg',
                component: CfgComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'liberacao',
                component: LiberacaoComponent,
                canActivate: [AuthService],
                data: {grupo: 1},
              },
              {
                path: 'atendimento',
                component: AtendimentoComponent,
                canActivate: [AuthService],
                data: {grupo: 3},
              },
              {
                path: 'logs',
                component: LogsComponent,
                canActivate: [AuthService],
                data: {grupo: 3},
              },
              {
                path: 'database',
                component: DatabaseManagementComponent,
                canActivate: [AuthService],
                data: {grupo: 2},
              },
              // {path: 'utilities/elevation', component: ElevationComponent},
              // {path: 'utilities/flexbox', component: FlexboxComponent},
              // {path: 'utilities/grid', component: GridComponent},
              // {path: 'utilities/icons', component: IconsComponent},
              // {path: 'utilities/widgets', component: WidgetsComponent},
              // {path: 'utilities/spacing', component: SpacingComponent},
              // {path: 'utilities/typography', component: TypographyComponent},
              // {path: 'utilities/text', component: TextComponent},
              // {path: 'pages/crud', component: AppCrudComponent},
              // {path: 'pages/calendar', component: AppCalendarComponent},
              // {path: 'pages/timeline', component: AppTimelineDemoComponent},
              // {path: 'pages/invoice', component: AppInvoiceComponent},
              // {path: 'pages/help', component: AppHelpComponent},
            ],
          },
          {path: 'error', component: AppErrorComponent},
          {path: 'nao-autorizado', component: AppAccessdeniedComponent},
          {path: 'notfound', component: AppNotfoundComponent},
          {path: 'login', component: AppLoginComponent},
          {path: '**', redirectTo: '/notfound'},
        ],
        {scrollPositionRestoration: 'enabled'}
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
