<p-confirmDialog header="Atenção"
  icon="pi pi-exclamation-triangle">
</p-confirmDialog>
<p-toast></p-toast>
<div class="p-field p-col-12 p-md-12 p-lg-12"
  *ngIf="loading">
  <p-progressBar mode="indeterminate"
    [style]="{ height: '6px' }"></p-progressBar>
</div>
<habil-grupo>
  <p-tabView>
    <p-tabPanel header="Restaurar banco de dados"
      [selected]="true">
      <div class="p-grid p-fluid">
        <div class="p-field p-col-12 p-md-6 p-lg-2">
          <habil-label name="hostHabilBD"
            label="Servidor Banco de dados">
            <p-dropdown appendTo="body"
              [options]="hosts"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="host"
              [autoDisplayFirst]="false"
              [showClear]="false"
              name="hostHabilBD">
            </p-dropdown>
          </habil-label>
        </div>
        <div class="p-field p-col-12 p-lg-10">
          <habil-label name="txtHost" *ngIf="permiteAlterarUrl"
            label="IP / Domínio">
            <input type="text"
              name="txtEmail"
              pInputText
              [(ngModel)]="host" />
          </habil-label>
        </div>
        <div class="p-field p-col-12 p-md-6 p-lg-2">
          <habil-label name="dropPrefix"
            label="Tipo">
            <p-dropdown appendTo="body"
              [options]="tipos"
              optionLabel="label"
              optionValue="value"
              [(ngModel)]="prefixo"
              [autoDisplayFirst]="false"
              [showClear]="false"
              name="dropPrefix">
            </p-dropdown>
          </habil-label>
        </div>
        <div class="p-field p-col-12 p-lg-10">
          <habil-label name="txtNomeDB"
            label="CNPJ / nome do banco (sem prefixo)">
            <input type="text"
              name="txtNomeDB"
              pInputText
              [(ngModel)]="database" />
          </habil-label>
        </div>
        <div class="p-field p-col-12 p-col-align-center">
          <br />
          <p-checkbox [binary]="true"
            [disabled]="true"
            label="Excluir banco de dados existente?"
            [(ngModel)]="drop"></p-checkbox>
        </div>
      </div>
      <div>
        <hr>
        <input type="file"
          (change)="onFileSelected($event)" />
        <br>
        <hr>
        <button [disabled]="loading"
          pButton
          (click)="restoreDatabase()">Restaurar Backup</button>
      </div>
    </p-tabPanel>
  </p-tabView>
</habil-grupo>