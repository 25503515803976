import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AbstractService } from 'src/app/services/abstract.service';
import { ClassificacaoService } from 'src/app/services/classificacao.service';
import { RegistroService } from 'src/app/services/registro.service';

@Component({
  selector: 'habil-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ClienteComponent implements OnInit {

  whereOptions = [];

  constructor(private breadcrumbService: BreadcrumbService, 
    public service: RegistroService, 
    public classificacaoService: ClassificacaoService,
    titleService: Title) {
    this.breadcrumbService.setItems([{label: 'Clientes'}]);
    titleService.setTitle('Clientes - ' + AbstractService.app);
  }

  ngOnInit(): void {
    this.classificacaoService.findAllTO(0, 9999, '', '', ['id', 'descricao'], []).subscribe({
      next: (val) => {
        const w = [];
        if (val) {
          for (const v of val) {
            w.push({
              label: v.descricao,
              value: v.id
            });
          }
          this.whereOptions = [...w];
        }
      }
    })
  }
}
