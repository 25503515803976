import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';

export class WebSocketHabil implements ISubject {
  private static instance: WebSocketHabil;
  socket: any;
  observadores: IObserver[] = [];

  private getUrl(): string {
    const url = `${environment.socket}`;

    return url;
  }

  private constructor() {
    this.observadores = [];
    try {
      this.socket = io(this.getUrl(), { transports: ['websocket'] });
      this.socket.on('error', (data) =>this.notify(data, 'error'));
      this.socket.on('importacao', (data) => this.notify(data, 'importacao'));
    } catch (e) {
      console.log(e);
    }
  }
  attach(observer: IObserver) {
    const obs = WebSocketHabil.getInstance().observadores;
    if (obs.indexOf(observer) == -1) {
      WebSocketHabil.getInstance().observadores.push(observer);
    }
  }
  detach(observer: IObserver) {
    const obs = WebSocketHabil.getInstance().observadores;
    if (obs) {
      const index = obs.indexOf(observer);
      if (index > -1) {
        obs.splice(index, 1);
      }
    }
  }

  notify(dados: any, tipo: 'error' | 'importacao') {
    const obs = WebSocketHabil.getInstance().observadores;
    if (obs?.length) {
      obs.forEach((o) => {
        o.update(dados, tipo);
      });
    }
  }

  conectado(): boolean {
    return this.socket.connected;
  }

  public static getInstance(): WebSocketHabil {
    if (!WebSocketHabil.instance) {
      WebSocketHabil.instance = new WebSocketHabil();
    }
    return WebSocketHabil.instance;
  }

}


export interface ISubject {
  attach(observer: IObserver): void;
  detach(observer: IObserver): void;
}

export interface IObserver {
  update(dados: any, tipo: 'error' | 'importacao'): void;
}
