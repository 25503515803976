import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteComponent } from './cliente.component';
import { CadastroClienteComponent } from './cadastro-cliente/cadastro-cliente.component';
import { CadastroLicencaComponent } from './cadastro-licenca/cadastro-licenca.component';
import { BaseListaModule } from 'src/app/utilities/base-lista/base-lista.module';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BotoesCadastroModule } from 'src/app/utilities/botoes-cadastro/botoes-cadastro.module';
import { EnderecoModule } from 'src/app/utilities/endereco/endereco.module';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { AutocompleteEditModule } from 'src/app/utilities/autocomplete-edit/autocomplete-edit.module';
import { CheckboxModule } from 'primeng/checkbox';
import { GridFonesModule } from 'src/app/utilities/grid-fones/grid-fones.module';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { DateEditModule } from 'src/app/utilities/date-edit/date-edit.module';
import { PickListModule } from 'primeng/picklist';
import { PanelModule } from 'primeng/panel';
import { ConsultaClienteComponent } from './consulta-cliente/consulta-cliente.component';
import { ConsultaContagemEmissaoComponent } from './consulta-contagem-emissao/consulta-contagem-emissao.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ConsultaLiberacoesHerpComponent } from './consulta-liberacoes-herp/consulta-liberacoes-herp.component';
import { TagModule } from 'primeng/tag';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ConsultaLiberacoesHeComponent } from './consulta-liberacoes-he/consulta-liberacoes-he.component';
import { CadastroLicencaHabilCobrancasComponent } from './cadastro-licenca-habil-cobrancas/cadastro-licenca-habil-cobrancas.component';
import { CadastroLicencaLimberChefComponent } from './cadastro-licenca-limber-chef/cadastro-licenca-limber-chef.component';
import { CadastroLicencaHabilFranqueadoraComponent } from './cadastro-licenca-habil-franqueadora/cadastro-licenca-habil-franqueadora.component';
import { TabViewModule } from 'primeng/tabview';
import { ClienteHabilFranqueadoraUsuarioComponent } from './cliente-habil-franqueadora-usuario/cliente-habil-franqueadora-usuario.component';
import { CadastroLicencaHabilMobilePdvComponent } from './cadastro-licenca-habil-mobile-pdv/cadastro-licenca-habil-mobile-pdv.component';
import { CadastroLicencaHabilPlayComponent } from './cadastro-licenca-habil-play/cadastro-licenca-habil-play.component';
import { CadastroLicencaHabilSpaComponent } from './cadastro-licenca-habil-spa/cadastro-licenca-habil-spa.component';


@NgModule({
  declarations: [
    ClienteComponent,
    CadastroClienteComponent,
    CadastroLicencaComponent,
    ConsultaClienteComponent,
    ConsultaContagemEmissaoComponent,
    ConsultaLiberacoesHerpComponent,
    ConsultaLiberacoesHeComponent,
    CadastroLicencaHabilCobrancasComponent,
    CadastroLicencaLimberChefComponent,
    CadastroLicencaHabilFranqueadoraComponent,
    ClienteHabilFranqueadoraUsuarioComponent,
    CadastroLicencaHabilMobilePdvComponent,
    CadastroLicencaHabilPlayComponent,
    CadastroLicencaHabilSpaComponent
  ],
  imports: [
    CommonModule,
    BaseListaModule,
    MessageModule,
    MessagesModule,
    FormsModule,
    BrowserModule,
    ConfirmDialogModule,
    GrupoModule,
    BotoesCadastroModule,
    TooltipModule,
    TagModule,
    AccordionModule,
    LabelModule,
    ProgressBarModule,
    RippleModule,
    ToastModule,
    ScrollPanelModule,
    InputTextModule,
    EnderecoModule,
    AutocompleteEditModule,
    TooltipModule,
    CheckboxModule,
    TabViewModule,
    GridFonesModule,
    TableModule,
    DropdownModule,
    InputNumberModule,
    DateEditModule,
    PickListModule,
    PanelModule,
    MultiSelectModule,
    DialogModule,
    OverlayPanelModule
  ]
})
export class ClienteModule {
}
