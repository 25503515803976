import { AbstractModel } from "./abstract-model";

export class DatabaseLocation extends AbstractModel {
  nome?: string;
  cnpj: string;
  databaseIp: string;
}

export const APP_1 = "https://app.habil.net.br/server-up"; //"http://ec2-34-193-51-106.compute-1.amazonaws.com";
export const APP_2 = "https://srv02.habil.net.br/server-up";//"http://ec2-54-158-193-111.compute-1.amazonaws.com";
export const APP_0 = "https://srv00.habil.net.br/server-up";


export const SERVIDORES_BD_NUVEM = [
  { label: "Nenhum", value: "" },
  { label: "Servidor 1", value: "54.235.214.105:5432" },
  { label: "Servidor 2", value: "44.202.75.71:5432" },
  { label: "RDS 1", value: "database-1-instance-1.c3kzxyl2psqd.us-east-1.rds.amazonaws.com:5432" }
];

export const SERVER_LIST = [
  { label: "APP 1", value: APP_1 },
  { label: "APP 2", value: APP_2 },
  { label: "APP 0", value: APP_0 }
];
