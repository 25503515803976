import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { softwares } from 'src/app/models/registro-produto';
import { AbstractService } from 'src/app/services/abstract.service';
import { HabilCobrancasProdutoService } from 'src/app/services/habil-cobrancas-produto.service';
import { HabilFranqueadoraProdutoService } from 'src/app/services/habil-franqueadora-produto.service';
import { LimberChefProdutoService } from 'src/app/services/limber-chef-produto.service';
import { HabilMobilePdvProdutoService } from 'src/app/services/habil-mobile-pdv-produto.service';
import { ProdutoService } from 'src/app/services/produto.service';
import { FormatUtils } from 'src/app/utilities/util/format-utils';

@Component({
  selector: 'habil-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ProdutoComponent implements OnInit {

  showSearch = true;
  search = "";
  _loading = false;
  selection: any;
  _selection = null;
  cols: any[] = [];
  objs: any[] = [];

  subActions: MenuItem[] = [];
  menuContexto: { label: string; icon: string; command: () => any; disabled: boolean; }[];
  first = 0;
  recordCount: number;
  loading: boolean;
  pSize: number = 100;
  index = 0;
  duplicar = false;
  showAbrir = true;
  showEditar = true;
  showExcluir = true;
  showAcoes = true;
  montaObjeto: Function = (objs) => {
    return objs;
  };
  tipos = softwares;
  tipo = "HABIL_10";

  constructor(private breadcrumbService: BreadcrumbService,
    public habil10Service: ProdutoService,
    public habilCobrancasService: HabilCobrancasProdutoService,
    public habilFranqueadoraService: HabilFranqueadoraProdutoService,
    public limberChefService: LimberChefProdutoService,
    public habilMobilePDVService: HabilMobilePdvProdutoService,
    public titleService: Title,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {
      
    this.breadcrumbService.setItems([{ label: 'Produtos' }]);
    this.titleService.setTitle('Produtos - ' + AbstractService.app);
  }

  _tratandoAcao = false;

  get service() {
    switch (this.tipo) {
      case "HABIL_COBRANCAS": {
        return this.habilCobrancasService;
      }
      case "LIMBER_CHEF": {
        return this.limberChefService;
      }
      case "HABIL_FRANQUEADORA": {
        return this.habilFranqueadoraService;
      }
      case "HABIL_MOBILE_PDV": {
        return this.habilMobilePDVService;
      }
    }
    return this.habil10Service;
  }

  getScrollHeight(): string {
    let fullHeight = window.innerHeight;
    let availableHeight = fullHeight - 270;
    let scrollHeight = availableHeight > 100 ? availableHeight + 'px' : '100px';

    return scrollHeight;
  }

  formatCpfCnpj(cpfCnpj) {
    return FormatUtils.cpfCnpj(cpfCnpj);
  }

  selectClick(item) {
    this._selection = item;
  }

  onListChange(event) {
    this.objs = [...event];
  }

  onPageChange(event) {
    this._loading = true;
    this.selection = undefined;
    if (event.page == null || event.page == undefined
      || event.rows == null || event.rows == undefined) {
      event.page = 0;
      event.rows = 10;
    }
    this.service.findAll(event?.page * event?.rows, event?.rows ?? 10, this.search, '', [])
      .subscribe(objs => {
        if (objs != undefined && objs != null) {
          this.objs = [...objs];
          this.objs = this.montaObjeto(this.objs)
        } else {
          this.objs = [];
        }
        this._loading = false;
      }, (e) => { this.service.handleError(e) });
  }

  getFieldValue(rowData: any, col: any): any {
    let field: string = col.field;
    let result: any = rowData;
    if (field.indexOf('.') > 0) {
      let fields: string[] = field.split('.');
      for (let i = 0; i < fields.length; i++) {
        try {
          if (result && result[fields[i]] != null && result[fields[i]] != undefined) {
            result = result[fields[i]];
          }
          else {
            result = undefined;
          }
        }
        catch (e) {
          result = undefined
        }
      }
    }
    else {
      result = rowData[col.field];
    }
    if (result != undefined && result != null) {
      if (col.type == 'date') {
        if (result != "") {
          let date = moment(result).toDate();
          return date;
        } else {
          return undefined;
        }
      } else {
        return result;
      }
    } else {
      return undefined;
    }
  }

  recarregarPesquisa() {
    this.reset();
  }

  ngOnInit(): void {
    this.cols = [...this.service.colunas()];
    this.reset();
  }

  reset() {
    if (this.search.includes('%')) {
      this.messageService.add({ severity: 'warn', summary: 'Atenção!', detail: 'Caractere inválido para busca: %' })
      return;
    }
    this.service.count(this.search, [])
      .subscribe(count => {
        this.recordCount = count;
        let pages = count / this.pSize;
        if (pages - Math.trunc(pages) > 0) {
          pages++;
        }
        this.onPageChange({ first: 0, rows: this.pSize, page: 0, pageCount: pages })
      }, (e) => this.service.handleError(e));
  }

  searchId() {
    try {
      if (!isNaN(parseInt(this.search))) {
        this._loading = true;
        this.service.findById(parseInt(this.search + "")).subscribe(obj => {
          if (obj) {
            this.recordCount = 1;
            this.objs = [];
            this.objs.push({ ...obj });
            this._loading = false;
          } else {
            this.recordCount = 0;
          }
        })
      }
    } catch (e) {
      console.log(e);
    }
  }

  get podeEditar() {
    if (!this.showEditar) return false;
    return true;
  }

  get podeApagar() {
    return true;
  }

  get podeAbrir() {

    return true;
  }

  novo() {
    this.service.novo();
  }


  abrir(item) {
    this.service.abrir(item);
  }

  edit(item) {
    this.service.edit(item);
  }

  acaoPadrao(item) {
    if (this.podeEditar) {
      this.service.edit(item);
    } else {
      this.service.abrir(item);
    }
  }

  doDelete(item) {
    this.confirmationService.confirm({
      message: 'Confirma a exclusão?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      accept: () => {
        this.service.delete(item.id)
          .subscribe(e => {
            if (item.deleted != null && item.deleted != undefined) { item.deleted = true; }
            this.reset();
          }, (e) => this.service.handleError(e));
      },
      reject: () => {

      }
    });
  }

}
