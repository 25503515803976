import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoComponent } from '../produto/produto.component';
import { CadastroProdutosComponent } from './cadastro-produtos/cadastro-produtos.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BaseListaModule } from 'src/app/utilities/base-lista/base-lista.module';
import { BotoesCadastroModule } from 'src/app/utilities/botoes-cadastro/botoes-cadastro.module';
import { GrupoModule } from 'src/app/utilities/grupo/grupo.module';
import { LabelModule } from 'src/app/utilities/label/label.module';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { AutocompleteEditModule } from 'src/app/utilities/autocomplete-edit/autocomplete-edit.module';
import { PickListModule } from 'primeng/picklist';
import { PanelModule } from 'primeng/panel';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { CadastroProdutoHabilCobrancasComponent } from './cadastro-produto-habil-cobrancas/cadastro-produto-habil-cobrancas.component';
import { CadastroProdutoLimberChefComponent } from './cadastro-produto-limber-chef/cadastro-produto-limber-chef.component';
import { CadastroProdutoHabilFranqueadoraComponent } from './cadastro-produto-habil-franqueadora/cadastro-produto-habil-franqueadora.component';
import { CadastroProdutoHabilMobilePdvComponent } from './cadastro-produto-habil-mobile-pdv/cadastro-produto-habil-mobile-pdv.component';
import { CadastroProdutoHabilPlayComponent } from './cadastro-produto-habil-play/cadastro-produto-habil-play.component';



@NgModule({
  declarations: [
    ProdutoComponent,
    CadastroProdutosComponent,
    CadastroProdutoHabilCobrancasComponent,
    CadastroProdutoLimberChefComponent,
    CadastroProdutoHabilFranqueadoraComponent,
    CadastroProdutoHabilMobilePdvComponent,
    CadastroProdutoHabilPlayComponent
  ],
  imports: [
    CommonModule,
    BaseListaModule,
    FormsModule,
    BrowserModule,
    ConfirmDialogModule,
    GrupoModule,
    BotoesCadastroModule,
    TooltipModule,
    AccordionModule,
    LabelModule,
    ProgressBarModule,
    RippleModule,
    ToastModule,
    ScrollPanelModule,
    InputTextModule,
    CheckboxModule,
    InputNumberModule,
    DropdownModule,
    AutocompleteEditModule,
    PickListModule,
    PanelModule,
    TableModule,
    PaginatorModule
  ],
  exports: [
    ProdutoComponent,
    CadastroProdutosComponent
  ],
})
export class ProdutoModule { }
