import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { AdminUsuario } from 'src/app/models/admin-usuario';
import { DatabaseLocation, SERVIDORES_BD_NUVEM } from 'src/app/models/database-location';
import { AbstractService } from 'src/app/services/abstract.service';
import { DatabaseLocationService } from 'src/app/services/database-location.service';
import { DBManagerService } from 'src/app/services/db-manager.service';
import { RegistroService } from 'src/app/services/registro.service';
import { SearchParams } from 'src/app/utilities/util/search/search-params';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'habil-database-management',
  templateUrl: './database-management.component.html',
  styleUrls: ['./database-management.component.scss'],
  providers: [MessageService, DialogService],
})
export class DatabaseManagementComponent implements OnInit {

  tipos = [
    { label: 'Hábil', value: 'tn' },
    { label: 'Limber Chef', value: 'lc' },
    { label: 'Personalizado', value: '' },
  ];

  prefixo: string = 'tn';
  host: string = 'database-1-instance-1.c3kzxyl2psqd.us-east-1.rds.amazonaws.com:5432';
  hosts = SERVIDORES_BD_NUVEM?.filter((h) => !!h?.value);
  backupFile!: File;
  database: string;
  drop = true; // Define se deve excluir e recriar o banco
  loading = false;
  dbLocation: DatabaseLocation = new DatabaseLocation();
  permiteAlterarUrl = false;

  constructor(private service: DBManagerService,
    public titleService: Title,
    public databaseLocationService: DatabaseLocationService,
    public confirmationService: ConfirmationService,
    public messageService: MessageService,
    public breadcrumbService: BreadcrumbService,
    public clienteService: RegistroService
  ) {

    if (!environment.api?.includes('appcc')) {
      this.host = '192.168.25.101';
    }

    this.breadcrumbService.setItems([
      { label: "Bancos de dados" },
    ]);
    this.titleService.setTitle("Bancos de dados");
  }

  ngOnInit(): void {
    let usuario: AdminUsuario = AbstractService.getLocalStorage( "usuario" );
    this.permiteAlterarUrl = usuario.grupoAcesso == 2 || usuario?.sysAdmin;
  }

  onFileSelected(event: any): void {
    this.backupFile = event.target.files[0];
  }

  restoreDatabase(): void {
    const database = this.prefixo + this.database;

    this.confirmationService.confirm({
      header: "Atenção",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Sim",
      rejectLabel: "Não",
      message: `Confirma a restauração para o banco de dados ${database}?`,
      accept: () => {
        if (!this.backupFile) {
          this.messageService.add({
            severity: 'error',
            detail: 'Arquivo de backup não selecionado.'
          });
          return;
        }
        this.loading = true;
        this.service.restoreDatabase(this.backupFile, database, this.host, this.drop)
          .subscribe({
            next: (response) => {
              this.messageService.add({
                severity: 'success',
                detail: 'Restauração realizada com sucesso.'
              });
              if (response.erro) {
                this.messageService.add({
                  severity: 'warn',
                  summary: 'Avisos:',
                  sticky: true,
                  detail: (response.erro as string)?.split('ERROR:')?.join('\nERROR:')
                });
              }
             
              if (this.prefixo == 'tn') {
                this.messageService.add({
                  severity: 'info',
                  detail: 'Salvando informações para conexão no Hábil 10...'
                });
                this.databaseLocationService.findAll(0, 1, "", "", [new SearchParams("cnpj", [database])])
                  .subscribe({
                    next: (val) => {
                      if (val?.length) {
                        this.dbLocation = val[0];
                      } else {
                        this.dbLocation.cnpj = database;
                      }

                      this.dbLocation.databaseIp = this.host?.replace(':5432', '') + ':5432'
                      this.databaseLocationService.save(this.dbLocation).subscribe((val) => {
                        this.loading = false;
                        this.confirmationService.confirm({
                          header: "Atenção",
                          icon: "pi pi-exclamation-triangle",
                          acceptLabel: "Sim",
                          rejectLabel: "Não",
                          message: `É recomendado trocar o servidor do Hábil 10 
                          para ser possível o login após a restauração.\n 
                          Deseja abrir o cadastro do cliente?`,
                          accept: () => {
                            this.loading = true;
                            this.clienteService.findByCnpj(database?.replace('tn', '')).subscribe(
                              {
                                next: (cliente) => {
                                  this.clienteService.edit(cliente[0]);
                                }, error: () => {
                                  this.loading = false;
                                }
                              }
                            )
                          }
                        });
                      });

                    }
                  });
              } else {
                this.loading = false;
              }
              console.log('Restauração realizada com sucesso:', response)
            },
            error: (error) => console.error('Erro na restauração:', error)
          });
      }
    });

  }

}
