import { Component, OnInit } from "@angular/core";
import { EventService } from "../service/eventservice";
import { SelectItem, MenuItem } from "primeng/api";
import { Product } from "../domain/product";
import { ProductService } from "../service/productservice";
import { BreadcrumbService } from "../../app.breadcrumb.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./tabledemo.scss"],
})
export class DashboardDemoComponent implements OnInit {
    cities: SelectItem[];

    products: Product[];

    chartData: any;

    events: any[];

    selectedCity: any;

    items: MenuItem[];

    fullcalendarOptions: any;

    urlDev: string = "https://dev.habil10.com.br/snake";
    urlDevSegura: SafeResourceUrl;

    constructor(
        private productService: ProductService,
        private eventService: EventService,
        private breadcrumbService: BreadcrumbService,
        private sanitizer: DomSanitizer
    ) {
        this.urlDevSegura = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlDev);
        this.breadcrumbService.setItems([
            { label: "Central do Cliente", routerLink: [""] },
        ]);
    }

    ngOnInit() {
        this.productService
            .getProducts()
            .then((data) => (this.products = data));
        this.eventService.getEvents().then((events) => {
            this.events = events;
            this.fullcalendarOptions = {
                ...this.fullcalendarOptions,
                ...{ events: events },
            };
        });

        this.cities = [];
        this.cities.push({ label: "Select City", value: null });
        this.cities.push({
            label: "New York",
            value: { id: 1, name: "New York", code: "NY" },
        });
        this.cities.push({
            label: "Rome",
            value: { id: 2, name: "Rome", code: "RM" },
        });
        this.cities.push({
            label: "London",
            value: { id: 3, name: "London", code: "LDN" },
        });
        this.cities.push({
            label: "Istanbul",
            value: { id: 4, name: "Istanbul", code: "IST" },
        });
        this.cities.push({
            label: "Paris",
            value: { id: 5, name: "Paris", code: "PRS" },
        });

        this.chartData = {
            labels: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
            ],
            datasets: [
                {
                    label: "First Dataset",
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: "#FFC107",
                },
                {
                    label: "Second Dataset",
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: "#03A9F4",
                },
            ],
        };

        this.items = [
            { label: "Save", icon: "pi pi-fw pi-check" },
            { label: "Update", icon: "pi pi-fw pi-refresh" },
            { label: "Delete", icon: "pi pi-fw pi-trash" },
        ];

        this.fullcalendarOptions = {
            initialDate: "2021-02-01",
            headerToolbar: {
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
            },
            editable: true,
            selectable: true,
            selectMirror: true,
            dayMaxEvents: true,
        };
    }
}
